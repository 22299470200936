// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconPhone(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Phone";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M9.22847252,9.228434 C7.64506885,10.8118113 5.81146459,12.3253896 5.08686291,11.6006 C4.0490605,10.5630149 3.40925901,9.6592278 1.1218537,11.4976015 C-1.16575161,13.3367752 0.591252475,14.5621576 1.5964548,15.5677432 C2.7564575,16.7275266 7.08066754,15.6287424 11.3548774,11.3552035 C15.6284874,7.08066469 16.7280899,2.75652657 15.5672872,1.59614318 C14.5618849,0.59075757 13.3364821,-1.1656173 11.4980778,1.12214996 C9.65927352,3.40891724 10.5626756,4.04890808 11.600678,5.08669323 C12.3250797,5.81148285 10.8118762,7.64485662 9.22847252,9.228434 Z",
                    fill: Icon.mapColor(props.color),
                    transform: "translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "
                  })
            });
}

var make = IconPhone;

export {
  make ,
}
/* Icon Not a pure module */
